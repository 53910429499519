.design-challenge .topnav {
    position: absolute;
}

.design-challenge .info {
  padding-top: calc(var(--line-height) * 3);
}

.design-challenge .preamble {
  padding-top: calc(var(--line-height) * 8);
}

.design-challenge p {
  margin-bottom: calc(var(--line-height) * 2);
}

.design-challenge h1 {

    margin-bottom: calc(var(--line-height) * 6);
  }

.design-challenge h2 {
  font-size: 1.25em;
  line-height: 1.4;
  margin-bottom: calc(var(--line-height) * 1);
}

.design-challenge figure {
    margin-bottom: calc(var(--line-height) * 2);
}

.design-challenge .grid .divider {
    grid-column-start: 1;
    grid-column-end: 9;
    height: calc(var(--line-height) * 8);
}

.design-challenge .grid .divider-small {
    grid-column-start: 1;
    grid-column-end: 9;
    height: calc(var(--line-height) * 3);
}

.design-challenge .illo path,
.design-challenge .illo circle,
.design-challenge .illo line,
.design-challenge .illo polygon {
    stroke: var(--color-gray-1);
}

.design-challenge .illo polygon.fill {
    fill: var(--color-gray-1);
    stroke: none;
}