
@media (min-width: 400px) {
 

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: var(--col-gap);
  max-width: var(--max-width);
  margin: 0 auto;
}

}
.grid .full {
  grid-column-start: 1;
  grid-column-end: 9;
}
.grid .col-start-1 {
  grid-column-start: 1;
}
.grid .col-start-2 {
  grid-column-start: 2;
}
.grid .col-start-3 {
  grid-column-start: 3;
}
.grid .col-start-4 {
  grid-column-start: 4;
}
.grid .col-start-5 {
  grid-column-start: 5;
}
.grid .col-start-6 {
  grid-column-start: 6;
}
.grid .col-start-7 {
  grid-column-start: 7;
}
.grid .col-start-8 {
  grid-column-start: 8;
}
.grid .col-start-9 {
  grid-column-start: 9;
}
.grid .col-end-1 {
  grid-column-end: 1;
}
.grid .col-end-2 {
  grid-column-end: 2;
}
.grid .col-end-3 {
  grid-column-end: 3;
}
.grid .col-end-4 {
  grid-column-end: 4;
}
.grid .col-end-5 {
  grid-column-end: 5;
}
.grid .col-end-6 {
  grid-column-end: 6;
}
.grid .col-end-7 {
  grid-column-end: 7;
}
.grid .col-end-8 {
  grid-column-end: 8;
}
.grid .col-end-9 {
  grid-column-end: 9;
}
.grid .links {
    grid-column-start: 6;
    grid-column-end: 9;
    text-align: right;
}
.grid figure.full,
.grid .interstitial {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: var(--col-gap);
}
.grid figure.full video {
  grid-column-start: 1;
  grid-column-end: 9;
}
.grid figure.full figcaption {
  grid-column-start: 2;
  grid-column-end: 6;
}
.grid .padding-top {
  padding-top: calc(var(--line-height)*4);
}
.grid .padding-top-double {
  padding-top: calc(var(--line-height)*8);
}
.grid .padding-top-triple {
  padding-top: calc(var(--line-height)*12);
}
.grid .padding-top-mega {
  padding-top: calc(var(--line-height)*24);
}
.grid .center-text {
  text-align: center;
}
.grid .right-text {
  text-align: right;
}

