.casestudy .page-content .grid .interstitial .info {
    padding-top: calc(var(--line-height)*4);
    margin-bottom: 0;
}

.casestudy .page-content .grid .title {
    margin-bottom: calc(var(--line-height)*3);
}

.casestudy .page-content .grid .info,
.casestudy .page-content .grid figure,
.casestudy .page-content .interstitial {
    margin-bottom: calc(var(--line-height)*4);
}

.casestudy .page-content .interstitial {
    grid-column-start: 1;
    grid-column-end: 9;
    padding-top: calc(var(--line-height)*7);
}

.casestudy .page-content .interstitial .title {
    grid-column-start: 1;
    grid-column-end: 5;
}

.casestudy .page-content .grid .info {
    grid-column-start: 5;
    grid-column-end: 9;
}

.casestudy .page-content .grid .gapless {
    margin-top: calc(var(--line-height) * calc(4 * -1));
}
