.topnav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--color-black);
    padding: calc(var(--line-height)*1) 24px;
}

.topnav .current {
    text-align: right;
}

.topnav a.current span {
    position: relative;
}

.topnav a.current span::before,
.topnav a.current span::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--color-gray-1);
    bottom: -2px;
    opacity: 0.0;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.topnav a.current span::after {
    opacity: 0;
    transform: scaleX(0.5);
    transform-origin: 0% 50%;
}

.topnav a.current:hover span::after {
    opacity: 1;
    transform: scaleX(1)
}

body.section-home .topnav .name,
body.section-home .topnav .title {
    opacity: 0;
}
