.cv .job .when,
.cv .grid .label {
    grid-column-start: 1;
    grid-column-end: 3;
}

.cv .grid .meta {
    grid-column-start: 3;
    grid-column-end: 7;
}

.cv .grid .description {
    grid-column-start: 3;
    grid-column-end: 7;
}

.cv .grid .info {
    grid-column-start: 5;
    grid-column-end: 9;
}

.cv .grid.job,
.cv .grid.edu,
.cv .grid.skills,
.cv .grid.recognition,
.cv .grid.download {
    padding-top: calc(var(--line-height)*3);
}

.cv .grid.job.featured {
    padding-bottom: calc(var(--line-height)*5);
}

.cv .grid.about {
    padding-top: calc(var(--line-height)*7);
}

.cv .job .description,
.cv .edu .description {
    padding-top: calc(var(--line-height)*0.5);
}

.cv .grid .info,
.cv .grid figure {
    margin-bottom: calc(var(--line-height)*4);
}

.cv .grid .info {
    padding-top: calc(var(--line-height)*4);
}

.cv .grid .meta span {
    display: inline-block;
}