.personal-projects .intro p {
    grid-column-start: 4;
    grid-column-end: 8;
}

.personal-projects .grid .when {
    grid-column-start: 1;
    grid-column-end: 3;
}

.personal-projects .grid .info {
    grid-column-start: 3;
    grid-column-end: 7;
}

.personal-projects section {
    padding-bottom: calc(var(--line-height)*10);
}

.personal-projects .description {
    padding-top: calc(var(--line-height)*0.5);
}

.personal-projects .intro {
    padding-bottom: calc(var(--line-height)*9);
}

.personal-projects .grid .info h2 {
    margin-bottom: calc(var(--line-height)*1);
}

.personal-projects .grid .info,
.personal-projects .grid figure,
.personal-projects .grid .links {
    margin-bottom: calc(var(--line-height)*3);
}

.personal-projects .grid .app-icon {
    grid-column-start: 7;
    grid-column-end: 9;
    text-align: right;
    margin-top: -20px;
    margin-bottom: var(--line-height);
}

.personal-projects .grid .app-icon img {
    display: inline-block;
}


