.home {
    max-width: 960px;
    margin: 0 auto;
    padding-top: calc(var(--line-height)*3);
}

.home_nav {
    max-width: var(--max-width);
    margin: 0 auto;
    margin-top: calc(var(--line-height) * 4);
}

.home_nav a {
    position: relative;
}

.home_nav a::before,
.home_nav a::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    background-color: white;
    bottom: -3px;
    opacity: 0.2;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.home_nav a::after {
    opacity: 0;
    transform: scaleX(0.5);
    transform-origin: 0% 50%;
}

.home_nav a:hover::after {
    opacity: .5;
    transform: scaleX(1)
}

.home_nav h2 {
    margin-top: calc(var(--line-height) * 1);
}


@media (min-width: 400px) {

    .home {
        padding-top: 0;
    }
    
    .home_top {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        column-gap: var(--col-gap);
        max-width: var(--max-width);
        margin: 0 auto;
        margin-bottom:  calc(var(--line-height) * 8);
        padding-top: calc(var(--line-height)*5);
    }

    .home_name {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .home_title {
        grid-column-start: 3;
        grid-column-end: 6;
        padding-top: calc(var(--line-height)*2);
    }

    .home_links {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        column-gap: var(--col-gap);
        max-width: var(--max-width);
        margin: 0 auto;
        margin-top: calc(var(--line-height) * 6);
    }
  }