footer {
    padding: calc(var(--line-height)*5) 24px calc(var(--line-height)*2);
    width: 100%;
}

@media (min-width: 960px) {
    footer {
        padding-left: 0;
        padding-right: 0;
    }
}

.section-home footer .next {
    display: none;
}

footer .contact a {
    display: inline-block;
    margin-right: 32px;
}

footer a.next.animated-underline::before {
    opacity: 0;
}