@font-face {
  font-family: 'Graphik-Medium';
  src: url('./Graphik-Medium.woff2');
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url('./Graphik-Regular.otf');
}

@font-face {
  font-family: 'Graphik-Bold';
  src: url('./Graphik-Bold.otf');
}

:root {
  --color-black:   #000;
  --color-white:   rgb(243,243,243);
  --color-gray-1:   rgb(204,204,204);
  --color-highlight: #4088F6;
  --font-size:       16px;
  --line-height:     24px;
  --max-width:      928px;
  --col-gap:         32px;
}

@media (min-width: 768px) {
  :root {
    --font-size:       18px;
    --line-height:     24px;
  }
}

body {
  background-color: var(--color-black);
}

::selection {
  background: var(--color-highlight);
}

.App {
  min-height: 100vh;
  color: var(--color-gray-1);
  font-family: "Graphik-Regular", sans-serif;
  font-size: var(--font-size);
  line-height: var(--line-height);
  position: relative;
}

.App a {
  text-decoration: none;
  color: inherit;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  font-size: calc(var(--font-size) * 1.7777777777);
  line-height: calc(var(--line-height) * 1.6666666667);
}

figcaption {
  font-size: calc(var(--font-size) * 0.75);
  line-height: calc(var(--line-height) * 0.8333333333);
  padding-top: calc(var(--line-height) * 1);
}

h3 {
  font-size: 1;
  font-family: 'Graphik-Medium';
}

h4 {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: 'Graphik-Medium';
}

main {
  padding: 24px;
  position: relative;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.page-content {
  padding-top: calc(var(--line-height)*7);
}

.info a,
.caption a,
.animated-underline {
  position: relative;
}

.info a,
.caption a {
  color: var(--color-white);
}

.info a::before,
.info a::after,
.caption a::before,
.caption a::after,
.animated-underline::before,
.animated-underline::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: white;
  bottom: -2px;
  opacity: 0.2;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.info a::after,
.caption a::after,
.animated-underline::after {
  opacity: 0;
  transform: scaleX(0.5);
  transform-origin: 0% 50%;
}

.info a:hover::after,
.caption a:hover::after,
.animated-underline:hover::after {
  opacity: .5;
  transform: scaleX(1)
}

